@charset "UTF-8";
:root {
  --color-darkblue: #1a1f5a;
  --color-darkblue-opaque: rgba(26, 31, 90, 0.4);
  --color-lime-opaque: rgba(0, 255, 119, 0.45);
}

.react-datepicker {
  font-family: 'Work Sans', sans-serif;
  background-color: #fff;
  color: var(--color-darkblue);
  border-radius: 10px;
  display: inline-block;
  padding: 36px 50px;
  width: 562px;
  height: 396px;
  position: relative;
  box-shadow: 2px 8px 16px rgba(158, 137, 131, 0.25), 6px 15px 40px rgba(158, 137, 131, 0.25);
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker__header {
  position: relative;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: var(--color-darkblue);
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  width: 10px;
  height: 10px;
}

.react-datepicker__current-month {
  color: var(--color-darkblue);
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.react-datepicker__day--outside-month {
  color: var(--color-darkblue-opaque);
}

.react-datepicker__navigation {
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 40px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  right: 80px;
}

.react-datepicker__navigation--next {
  right: 50px;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: 0;
  font-size: 20px;
  width: 0;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
}

.react-datepicker__day-name {
  align-items: flex-start;
  color: var(--color-darkblue-opaque);
  font-weight: bold;
  text-transform: uppercase;
}

.react-datepicker__day {
  cursor: pointer;
  font-size: 1.125rem;
}

.react-datepicker__day--selected {
  border-radius: 50%;
  background-color: var(--color-lime-opaque);
  font-weight: bold;
}
.react-datepicker__day--highlighted {
  font-weight: bold;
}

.react-datepicker__day--in-range {
  background-color: var(--color-lime-opaque);
  border-radius: 50%;
  color: white;
  font-weight: bold;
}
.react-datepicker__day--range-start {
  background-color: var(--color-lime-opaque);
  border-radius: 50%;
  color: white;
  font-weight: bold;
}
.react-datepicker__day--in-selecting-range {
  background-color: var(--color-lime-opaque);
  border-radius: 50%;
  color: white;
  font-weight: bold;
}
